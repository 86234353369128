import useNotification from '@/composables/useNotification';
import { cloneDeepObject, parseJson } from '@/utils/common';
import { defineStore } from 'pinia';
import { sentryCaptureException } from '../use-cases/sentry';
import type { Component, Section } from '../utils/types';
import type { SectionCategory } from '@/types/graphql';
import { convertComponentToJSON } from '@/modules/editor/modules/common/utils/section/component';
const textEncoder = new TextEncoder();
const EXCLUDES_SECTIONS_PICK_LINK = ['Sticky', 'Dialog'];

type BuildWithSectionItem = { id: string; name: string; pageType: string } | null;
export type State = {
  items: Section[];
  deletedIds: {
    id: string;
    cid?: string;
    isCustom?: boolean;
    isCustomNew?: boolean;
    isShopifySection?: boolean;
  }[];
  linkedThemeSectionIds: string[];
  unlinkThemeSectionIds: string[];
  linkThemeSectionIds: string[];
  buildWithSectionLoadingIds: string[];
  listSectionSnapshot: Section[];
  sectionPosition: string[];
  buildWithSectionCurrentCategory: SectionCategory | null;
  buildWithSectionCurrentFocalPoint: { x: number; y: number } | null;
  isInsideTriangle: boolean;
  sectionDragging: string;
  buildWithSectionDraggingInfo: BuildWithSectionItem;
  isHoverBuildWithSectionList: boolean;
  isHoverBuildWithSectionCategoryList: boolean;
  openedModal: string;
};

const getScrollToSectionName = (tag: string) => {
  switch (tag) {
    case 'Section':
      return 'Section';
    case 'Sticky':
      return 'Sticky';
    case 'Dialog':
      return 'Dialog';
    default:
      return 'Section';
  }
};

export const useSectionStore = defineStore('section', {
  state: (): State => ({
    items: [],
    deletedIds: [],
    linkedThemeSectionIds: [],
    unlinkThemeSectionIds: [],
    linkThemeSectionIds: [],
    listSectionSnapshot: [],
    buildWithSectionLoadingIds: [],
    sectionPosition: [],
    buildWithSectionCurrentCategory: null,
    buildWithSectionCurrentFocalPoint: null,
    isInsideTriangle: false,
    sectionDragging: '',
    buildWithSectionDraggingInfo: null,
    isHoverBuildWithSectionList: false,
    isHoverBuildWithSectionCategoryList: false,
    openedModal: '',
  }),
  getters: {
    getListSectionSnapshotMap(state) {
      return state.listSectionSnapshot.reduce((map, section) => {
        if (section.cid && section.id && !section.isThemeSection) {
          map.set(section.cid, section);
        }
        return map;
      }, new Map<string, Section>());
    },
    sizeBySection(state) {
      return state.items.reduce((acc, item) => {
        const size = item.component ? textEncoder.encode(item.component).length : 0;
        return {
          ...acc,
          [item.id]: Math.ceil(size / 1024),
        };
      }, {} as Record<string, number>);
    },
    isThemeSectionEmpty(state) {
      const container = state.items[0];
      if (!container) return true;

      const component = convertComponentToJSON(container?.component ?? '');
      if (!component) return true;

      return !(component?.childrens?.[0]?.childrens?.length ?? 0);
    },
    getDeletedIds(state) {
      return state.deletedIds.filter((item) => !item.isShopifySection);
    },
    getShopifyDeletedIds(state) {
      return state.deletedIds.filter((item) => item.isShopifySection);
    },
    getLinkedThemeSectionIds(state) {
      return state.linkedThemeSectionIds;
    },
    getBuildWithSectionLoadingIds(state) {
      return state.buildWithSectionLoadingIds;
    },
    getUnlinkThemeSectionIds(state) {
      return state.unlinkThemeSectionIds;
    },
    getLinkThemeSectionIds(state) {
      return state.linkThemeSectionIds;
    },
    getBuildWithSectionDraggingInfo(state) {
      return state.buildWithSectionDraggingInfo;
    },
    getItems(state) {
      return state.items;
    },
    getGempageItems(state) {
      return state.items.filter((item) => !item.isShopifySection);
    },
    getShopifyItems(state) {
      return state.items.filter((item) => item.isShopifySection);
    },
    modals(state) {
      return state.items.filter((v) => {
        const data = parseJson<Component>(v.component ?? '{}');
        return data.tag === 'Dialog';
      });
    },
    stickies(state) {
      return state.items.filter((v) => {
        const data = parseJson<Component>(v.component ?? '{}');
        return data.tag === 'Sticky';
      });
    },
    getScrollToElement(state) {
      return state.items
        .map((item, index) => {
          const component = parseJson<Component>(item.component ?? '{}');
          return {
            id: component.uid,
            handle: component.label,
            title: component.tag,
            name: item.renamed ? item.name : `${getScrollToSectionName(component.tag)} ${index + 1}`,
            isThemeSection: item.isThemeSection,
            index: index,
          };
        })
        .filter((item) => !EXCLUDES_SECTIONS_PICK_LINK.includes(item.title));
    },
    getAllPopupAndSection(state) {
      return state.items.map((item, index) => {
        const component = parseJson<Component>(item.component ?? '{}');
        return {
          id: component.uid,
          handle: component.label,
          title: component.tag,
          index: index,
          name: component?.settings?.name
            ? component?.settings?.name
            : `${getScrollToSectionName(component.tag)} ${index + 1}`,
          type: component.tag,
        };
      });
    },
    getActiveItems(state) {
      return state.items.filter((item) => {
        if (!item.display) {
          return;
        }
        return item;
      });
    },
    getItemByCid(state) {
      return (cid: string) => state.items.find((item) => item.cid === cid);
    },
    getPosition() {
      return (cid: string) => this.getActiveItems.findIndex((item) => item.cid === cid);
    },
    getThemeSections(state) {
      return () => state.items.filter((item) => item.isThemeSection);
    },
    getSectionBefore(state) {
      return (cid: string) => {
        const index = this.getActiveItems.findIndex((item) => item.cid === cid);
        if (index == 0) {
          return null;
        }
        const item = state.items[index - 1];
        return item;
      };
    },
    getSectionAfter(state) {
      return (cid: string) => {
        const index = this.getActiveItems.findIndex((item) => item.cid === cid);
        if (index == this.getActiveItems.length - 1) {
          return null;
        }
        const item = state.items[index + 1];
        return item;
      };
    },

    getSectionSnapshot(state) {
      return state.listSectionSnapshot;
    },

    getSectionPosition(state) {
      return state.sectionPosition;
    },
    getBuildWithSectionCurrentCategory(state) {
      return state.buildWithSectionCurrentCategory;
    },
    getBuildWithSectionCurrentFocalPoint(state) {
      return state.buildWithSectionCurrentFocalPoint;
    },
    getIsInsideTriangle(state) {
      return state.isInsideTriangle;
    },
    getSectionDragging(state) {
      return state.sectionDragging;
    },
    getIsHoverBuildWithSectionList(state) {
      return state.isHoverBuildWithSectionList;
    },
    getIsHoverBuildWithSectionCategoryList(state) {
      return state.isHoverBuildWithSectionCategoryList;
    },
    getOpenedModal(state) {
      return state.openedModal;
    },
  },
  actions: {
    clear() {
      this.$reset();
    },
    moveItem(cid: string, afterCid: string | null) {
      const item = this.items.find((item) => item.cid === cid) as Section; // cache
      // Remove current
      const indexCurrent = this.items.findIndex((item) => item.cid === cid);
      this.items.splice(indexCurrent, 1);

      // Append
      if (afterCid) {
        const anchorIndex = this.items.findIndex((item) => item.cid === afterCid);
        this.items.splice(anchorIndex + 1, 0, item);
      } else {
        this.items.splice(0, 0, item);
      }
    },
    setItem(item: Section, index: number) {
      const existsCid = this.items.find((el) => el.cid == item.cid);
      const existsId = this.items.find((el) => el.id && item.id && el.id == item.id);
      if (existsCid && !item.isThemeSection) {
        sentryCaptureException(
          'setItem',
          'Duplicate cid',
          { item, items: this.items },
          {
            level: 'fatal',
          },
        );
        const { handleError } = useNotification();
        handleError('Duplicate cid');
      } else if (existsId && !item.isThemeSection) {
        sentryCaptureException(
          'setItem',
          'Duplicate id',
          { item, items: this.items },
          {
            level: 'fatal',
          },
        );
        const { handleError } = useNotification();
        handleError('Duplicate id');
      } else {
        this.items.splice(index, 0, item);
      }
    },
    deleteItem(cid: string) {
      const item = this.items.find((item: Section) => item.cid === cid);
      if (item?.id) {
        const clone = cloneDeepObject(item);
        this.deletedIds.push({
          id: clone.id,
          cid: clone.cid,
          isCustom: clone.isCustom,
          isCustomNew: clone.isCustomNew,
          isShopifySection: clone.isShopifySection,
        });
      }

      this.items = this.items.filter((item: Section) => item.cid !== cid);
    },
    setLinkedThemeSectionId(value: string[]) {
      this.linkedThemeSectionIds = [...value];
      this.linkedThemeSectionIds = Array.from(new Set(this.linkedThemeSectionIds));
    },
    setUnlinkThemeSectionId(cid: string) {
      const itemIndex = this.items.findIndex((item: Section) => item.cid === cid);

      if (itemIndex === -1) return;

      const currentSection = this.items[itemIndex];
      const isLinked = this.linkedThemeSectionIds.includes(currentSection.id);
      const isWaitToLink = this.linkThemeSectionIds.includes(currentSection.id);
      const isLastThemeSectionOnPage =
        this.items.filter((section: Section) => section.id === currentSection.id)?.length === 1;

      if (isWaitToLink) {
        const index = this.linkThemeSectionIds.indexOf(currentSection.id);
        this.linkThemeSectionIds.splice(index, 1);
      }

      if (isLinked && isLastThemeSectionOnPage) {
        const index = this.unlinkThemeSectionIds.indexOf(currentSection.id);
        if (index === -1) {
          this.unlinkThemeSectionIds.push(currentSection.id);
        }
      }

      this.deleteItem(cid);
    },
    setLinkThemeSectionId(value: string[]) {
      // Remove `sectionId` from unlink list when all theme sections with this `sectionId` was deleted from page
      value.forEach((id: string) => {
        const sectionsFound = this.items.filter((section: Section) => section.id === id)?.length;
        const isWaitToUnlink = this.unlinkThemeSectionIds.includes(id);
        if (isWaitToUnlink && sectionsFound <= 1) this.removeFromUnlinkThemeSectionList(id);
      });

      // Filter out theme section ids have not linked to page
      const filteredValue = value.filter((id: string) => !this.linkedThemeSectionIds.includes(id));

      this.linkThemeSectionIds = Array.from(new Set([...filteredValue]));
    },
    setEmptyUnlinkThemeSection() {
      this.unlinkThemeSectionIds = [];
    },
    setBuildWithSectionLoadingIds(value: string[]) {
      this.buildWithSectionLoadingIds = [...value];
      this.buildWithSectionLoadingIds = Array.from(new Set(this.buildWithSectionLoadingIds));
    },
    removeFromUnlinkThemeSectionList(removedId: string) {
      this.unlinkThemeSectionIds = this.unlinkThemeSectionIds.filter((id) => id !== removedId);
    },
    removeFromLinkedThemeSectionList(removedId: string) {
      this.linkedThemeSectionIds = this.linkedThemeSectionIds.filter((id) => id !== removedId);
    },
    removeFromLinkThemeSectionList(removedId: string) {
      this.linkThemeSectionIds = this.linkThemeSectionIds.filter((id) => id !== removedId);
    },
    update(newItem: Section) {
      if (this.items?.length) {
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i];
          if (item.cid === newItem.cid) {
            this.items[i] = { ...item, ...newItem };
            break;
          }
        }
      }
    },
    deleteIdInDeleted(id: string) {
      this.deletedIds = this.deletedIds.filter((item) => item.id !== id);
    },
    deleteAll() {
      const newDeletedSections = this.items.map((section) => {
        return { id: section.id, cid: section.cid, isCustom: section.isCustom, isCustomNew: section.isCustomNew };
      });
      this.deletedIds = [...this.deletedIds, ...newDeletedSections];
      this.items = [];
    },
    clearFlagImportFromLibrary() {
      this.items.forEach((item) => {
        if (item.isImportFromLibrary) {
          item.isImportFromLibrary = false;
        }
      });
    },
    replaceItems(items: Section[]) {
      this.items = items;
    },
    clearDeletedIds() {
      this.deletedIds = [];
    },

    setListSectionSnapshot(items: Section[]) {
      const newItems = items.filter((item) => this.listSectionSnapshot.findIndex((el) => el.cid === item.cid) === -1);
      this.listSectionSnapshot = this.listSectionSnapshot.concat(newItems).map((item) => {
        const updatedItem = items.find((el) => el.cid === item.cid);
        return {
          ...item,
          ...updatedItem,
        };
      });
    },

    updateNewData(newItem: Section) {
      this.items?.forEach((item) => {
        if (item.cid !== newItem.cid) {
          return;
        }
        item.id = newItem.id;
        item.createdAt = newItem.createdAt;
        item.deletedAt = newItem.deletedAt;
        item.updatedAt = newItem.updatedAt;
      });
    },

    setSectionPosition(position: string[]) {
      this.sectionPosition = position;
    },

    updateItemName(cid: string, sectionName: string) {
      this.items.forEach((item) => {
        if (item.cid === cid) {
          item.name = sectionName;
        }
      });
    },

    setRenamedSection(renamedCids: Record<string, boolean>) {
      this.items?.forEach((item) => {
        const component = parseJson<Component>(item.component ?? '{}');
        const originalName = `Section ${component?.uid}`;
        const isChangedName = item?.name !== `Section ${item?.cid}` && item?.name !== originalName;
        const renamed = renamedCids[item.cid || ''] || item.renamed || isChangedName;
        item.renamed = renamed;
        if (component.tag === 'Section') {
          item.name = renamed ? item.name : originalName;
        }
      });
    },
    setBuildWithSectionCurrentCategory(value: SectionCategory | null) {
      this.buildWithSectionCurrentCategory = value;
    },
    setBuildWithSectionCurrentFocalPoint(value: { x: number; y: number } | null) {
      this.buildWithSectionCurrentFocalPoint = value;
    },
    setIsInsideTriangle(value: boolean) {
      this.isInsideTriangle = value;
    },
    setSectionDragging(value: string) {
      this.sectionDragging = value;
    },
    setBuildWithSectionDraggingInfo(value: BuildWithSectionItem) {
      this.buildWithSectionDraggingInfo = value;
    },
    setIsHoverBuildWithSectionList(value: boolean) {
      this.isHoverBuildWithSectionList = value;
    },
    setIsHoverBuildWithSectionCategoryList(value: boolean) {
      this.isHoverBuildWithSectionCategoryList = value;
    },
    setOpenedModal(value: string) {
      this.openedModal = value;
    },
  },
});

export default useSectionStore;
